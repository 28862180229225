@tailwind base;
@tailwind components;
@tailwind utilities;

/* Removes the default style for the scrolling bar. */
*:not(.allowScrollbar) {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

*:not(.allowScrollbar)::-webkit-scrollbar {
  display: none;
}

.allowScrollbar::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

.allowScrollbar::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.allowScrollbar::-webkit-scrollbar-thumb {
  background-color: #11171a;
  border-radius: 10px;
}

/* Displays the content full-screen. */
body {
  border: 0 !important;
  margin: 0 !important;
}

/* Center container both vertically and horizontally */
.center {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.center-horizontally {
  display: flex;
  justify-content: center;
  width: 100%;
}

.center-vertically {
  align-items: center;
  height: 100%;
}

/* Useful classes for vertical/horizontal spacing */
.mb-10 {
  margin-bottom: 10px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.p-25 {
  padding: 25px !important;
}

/* Links */
a.purple-link {
  color: #d6b1ff !important;
  text-decoration: none;
}

/* Full width/height */
.h-100 {
  height: 100% !important;
}

.w-100 {
  width: 100% !important;
}

/* No overflow for full width elements with margin/padding */
.no-overflow {
  box-sizing: border-box;
}

/* Each option selected through a switch has a variant: contained/outlined */
.contained-selected {
  opacity: 0.5;
}

.outlined-selected {
  border-bottom: 3px solid white !important;
  padding: 0 10px 10px 10px;
}

/* Classes useful for the results modal (input vs output vs ref) */
.bold-test-name {
  font-size: 1.4rem !important;
  font-weight: 700 !important;
  padding-bottom: 10px;
}

/* Submissions styles */
.failed-submission {
  background-color: #cd5c5c;
}
.passed-submission {
  background-color: #168b87;
}
.pending-submission {
  background-color: #3f5171;
}

ul {
  list-style: disc !important;
}

li > ul {
  list-style: circle !important;
}

/* TEMP HARDCODE VALS FOR MD PREVIEW */

.wmde-markdown {
  color: white !important;
}

.wmde-markdown pre code {
  color: white !important;
  background: #161b22 !important;
}

.wmde-markdown table tr:nth-child(2n) {
  background-color: #161b22 !important;
}

.wmde-markdown table tr {
  background-color: #0d1117 !important;
}

.wmde-markdown table td {
  border-color: #55575a !important;
}

.wmde-markdown table th {
  border-color: #55575a !important;
}

.MuiClockPicker-root .MuiButtonBase-root span {
  color: #000 !important;
}
